import { globalDef } from "./siteDefinitions";
import { requestIdleCallbackShim } from "./utility";

/**
 * Allows elements to animate in from the top of the screen.
 * Mostly used in the navbar, but can be used anywhere if things should come from the top.
 *
 * There needs to be an initiator (ex: button) and then the slidey element.
 * The initiator should have a class of 'js-slide-control' and the slidey should have a class of slide-element.
 */
export class SlideToggle {
    private slideToggles: NodeListOf<HTMLElement> = document.querySelectorAll<HTMLElement>(".js-slide-control");
    private slideContainer: HTMLElement | null = document.querySelector(".slide-container");
    private slideTarget: HTMLElement | null = null;
    private currentToggle: HTMLElement | null = null;

    constructor() {
        this.handleClose = this.handleClose.bind(this);

        this.attachSlide();

        this.attachPolyfills();

        if (this.slideContainer) {
            const slideElements = this.slideContainer.querySelectorAll<HTMLElement>(".slide-element");
            for (const slideElement of slideElements) {
                this.setInert(slideElement);
            }

            this.slideContainer.style.display = "block";
        }
    }

    /**
     * Close all dem slides
     */
    public closeAll() {
        for (const slideToggle of this.slideToggles) {
            const target = document.getElementById(slideToggle.dataset.slideTarget as string);

            this.slideClose(target, slideToggle);
        }
    }

    /**
     * Attach the event to the initiator element.
     */
    private attachSlide() {
        for (const toggle of this.slideToggles) {
            toggle.addEventListener("click", () => {
                this.currentToggle = toggle;
                this.determineSlide();
            });
        }
    }

    /**
     * Determine whether the element needs to slide up or down.
     */
    private determineSlide() {
        this.slideTarget = document.getElementById(this.currentToggle!.dataset.slideTarget as string);

        if (this.slideTarget!.classList.contains("slide-open")) {
            this.slideClose();
        } else {
            this.slideOpen();
        }
    }

    /**
     * Slide the element down and set the aria-expanded to true.
     */
    private slideOpen() {
        if (this.slideTarget) {
            // Emit an event to the overall container
            this.emitEvent(this.slideContainer as HTMLElement, "slide-gonna-move");

            // Emit an event specific to the slidey target
            this.emitEvent(this.slideTarget, "slide-opening");

            this.closeOthers();
            this.slideTarget.classList.add("slide-open");
            this.currentToggle!.setAttribute("aria-expanded", "true");
            this.attachClose();

            this.emitEvent(this.slideTarget, "slide-opened");

            this.unsetInert(this.slideTarget);
        }
    }

    /**
     * Closes the slide element. Takes optional target and toggle options. Useful when closing from external trigger.
     * @param slideTarget HTMLElement | null
     * @param currentToggle HTMLElement | null
     */
    private slideClose(slideTarget = this.slideTarget, currentToggle = this.currentToggle) {
        if (slideTarget) {
            this.emitEvent(slideTarget, "slide-closing");

            slideTarget.classList.remove("slide-open");
            currentToggle!.setAttribute("aria-expanded", "false");
            this.removeClose();

            this.emitEvent(slideTarget, "slide-closed");

            this.setInert(slideTarget);
        }
    }

    /**
     * Only allow one slidey thing to be slided down. Calls slide up to handle sliding everything up.
     */
    private closeOthers() {
        // Call the element that is currently open.
        const slideTarget = document.querySelector(".slide-open") as HTMLElement;

        if (slideTarget) {
            // Grab the id of the open element and grab the initiator element based on the data attribute.
            const slideId = slideTarget.id;
            const currentToggles = document.querySelectorAll<HTMLElement>(`.js-slide-control[data-slide-target="${slideId}"]`);

            // Make sure all the aria-expanded attributes are set to false. Lookin at you account icon.
            for (const currentToggle of currentToggles) {
                this.slideClose(slideTarget, currentToggle);
            }
        }
    }

    /**
     * The slide elements can also be closed by clicking on the body or using the esc key. This handles that logic
     */
    private handleClose(e: Event) {
        const slideTarget = document.querySelector(".slide-open") as HTMLElement;
        const slideId = slideTarget.id;
        const currentToggles = document.querySelectorAll<HTMLElement>(`.js-slide-control[data-slide-target="${slideId}"]`);

        if (e.type === "click") {
            if ((e.target as HTMLElement).classList.contains("slide-element")) {
                return;
            }

            for (const currentToggle of currentToggles) {
                this.slideClose(slideTarget, currentToggle);
            }
        }

        if ((e as KeyboardEvent).keyCode === 27) {
            for (const currentToggle of currentToggles) {
                this.slideClose(slideTarget, currentToggle);
            }
        }
    }

    /**
     * Dynamically import and attach some polyfills
     */
    private attachPolyfills() {
        if (!(typeof window.CustomEvent === "function")) {
            import(/* webpackChunkName: "ce-polyfill" */ "./polyfills/ce-polyfill" as any).then((customEvent) => customEvent.default()); // eslint-disable-line
        }
    }

    /**
     * Set the inert attribute to true. This hides the dropdowns from screen readers
     */
    private setInert(slideTarget: HTMLElement | null) {
        if (!Element.prototype.matches) {
            Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
        }

        requestIdleCallbackShim();

        (window as any).requestIdleCallback(() => slideTarget!.setAttribute("inert", "true"));
    }

    /**
     * Set the inert attribute to false. This shows the dropdown in screen readers.
     */
    private unsetInert(slideTarget: HTMLElement | null) {
        if (!Element.prototype.matches) {
            Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
        }

        requestIdleCallbackShim();

        (window as any).requestIdleCallback(() => slideTarget!.removeAttribute("inert"));
    }

    /**
     * Emit a custom event
     * @param eventElement HTMLElement
     * @param eventName string
     */
    private emitEvent(eventElement: HTMLElement, eventName: string) {
        const createdEvent = new CustomEvent(eventName);

        eventElement!.dispatchEvent(createdEvent);
    }

    /**
     * Attach the extra close event handlers
     */
    private attachClose() {
        const main = document.querySelector("main");

        if (main) {
            main.addEventListener("click", this.handleClose);
        }
        globalDef.body.addEventListener("keyup", this.handleClose);
    }

    /**
     * Clean up and remove the event handlers once we're done
     */
    private removeClose() {
        const main = document.querySelector("main");

        if (main) {
            main.removeEventListener("click", this.handleClose);
        }
        globalDef.body.removeEventListener("keyup", this.handleClose);
    }
}
