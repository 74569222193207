import "../scss/navbar.scss";

import "wicg-inert";
import { handleAccountWrapClose, handleAccountWrapOpen, handleSearchClosed, handleSearchOpen } from "./modules/gaEvents";
import { NavbarToggle } from "./modules/NavbarToggle";
import { SlideToggle } from "./modules/SlideToggle";
import { mobileWidth } from "./modules/utility";

// Init all the slide downs
const slideToggle = new SlideToggle();
let mobileNavbar: NavbarToggle;

const slideContainer = document.querySelector(".slide-container");
const searchWrap = document.getElementById("searchWrap");
const accountWrap = document.querySelector(".login-wrap-outer");

// Set the true if mobile nav has run. That way we can stop checking
let mobileNavSetup = false;

/**
 * Setup the mobile nav if the width matches
 */
function setupMobileNav() {
    const navbarMenu = document.querySelector(".navbar-nav") as HTMLElement;

    if (mobileWidth.matches && slideContainer) {
        // This should only run the first time things are set up
        if (!mobileNavSetup) {
            mobileNavbar = new NavbarToggle();

            slideContainer.addEventListener("slide-gonna-move", () => mobileNavbar.closeNavbar());

            navbarMenu?.addEventListener("nav-opened", () => slideToggle.closeAll()); // Close search wrap
        }

        // Set this later so there isn't a flash of menu on resize
        navbarMenu.classList.add("navbar-transition");

        // Manually set inert if on mobile
        mobileNavbar.setInert();

        mobileNavSetup = true;
    }

    // This should only run if the mobile nav has been set up at some point
    if (!mobileWidth.matches && mobileNavSetup) {
        navbarMenu.classList.remove("navbar-transition");
        mobileNavbar.unsetInert();
    }
}

const delay = 100;
let resizeTaskId: NodeJS.Timeout | null = null;

/**
 * Use this to debounce the resize check
 */
function debounceMobileNav() {
    if (resizeTaskId !== null) {
        clearTimeout(resizeTaskId);
    }

    resizeTaskId = setTimeout(() => {
        resizeTaskId = null;
        setupMobileNav();
    }, delay);
}

if (searchWrap) {
    searchWrap.addEventListener("slide-opened", () => {
        // Have to delay because of inert
        setTimeout(() => {
            (document.querySelector("#main-search input[type=text]") as HTMLElement).focus();
        }, 100);

        handleSearchOpen();
    });

    searchWrap.addEventListener("slide-closed", handleSearchClosed);
}

if (accountWrap) {
    accountWrap.addEventListener("slide-opened", () => {
        // Have to delay because of inert
        setTimeout(() => {
            (document.getElementById("user_login") as HTMLElement).focus();
        }, 100);

        handleAccountWrapOpen();
    });

    accountWrap.addEventListener("slide-closed", handleAccountWrapClose);

    const loginForm = document.getElementById("loginform") as HTMLFormElement;
    loginForm.addEventListener("submit", () => loginLoader(loginForm));
}

/**
 * Disable the inputs and show a loader on login
 */
function loginLoader(loginForm: HTMLFormElement) {
    const submitButton = loginForm.querySelector("#wp-submit") as HTMLInputElement;
    const loader = document.querySelector(".login-loading") as any;

    submitButton.disabled = true;

    loginForm.classList.add("submitting");
    loader.loading = true;
}

setupMobileNav();

// if (!mobileNavSetup) {
// Make sure the mobile works on resize
window.addEventListener("resize", debounceMobileNav, { passive: true });
// }
