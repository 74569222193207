import { requestIdleCallbackShim } from "./utility";

/**
 * The mobile nav menu. Just adds a class and changes the aria attribute.
 * Fairly simple
 */
export class NavbarToggle {
    private navButton: HTMLElement | null = document.querySelector(".hamburger");
    private navMenu: HTMLElement | null = document.querySelector(".navbar-nav");

    constructor() {
        if (this.navButton) {
            this.navButton.addEventListener("click", () => {
                this.navMenu!.classList.toggle("nav-open");

                this.handleToggle();
            });
        }
    }

    /**
     * Manually close the navbar. This can be used anywhere
     */
    public closeNavbar() {
        const { navMenu } = this;
        if (navMenu && navMenu.classList.contains("nav-open")) {
            navMenu.classList.remove("nav-open");
            this.handleToggle(true);
        }
    }

    /**
     * Set the inert attribute to true. This hides the menu from screen readers
     */
    public setInert() {
        if (!Element.prototype.matches) {
            Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
        }

        requestIdleCallbackShim();

        (window as any).requestIdleCallback(() => this.navMenu!.setAttribute("inert", "true"));
    }

    /**
     * Set the inert attribute to false. This shows the menu in screen readers.
     */
    public unsetInert() {
        if (!Element.prototype.matches) {
            Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
        }

        requestIdleCallbackShim();

        (window as any).requestIdleCallback(() => this.navMenu!.removeAttribute("inert"));
    }

    /**
     * Set the aria-expanded
     * @param collapse boolean
     */
    private handleToggle(collapse = false) {
        if (collapse || this.navButton!.getAttribute("aria-expanded") === "true") {
            this.navButton!.setAttribute("aria-expanded", "false");
            this.emitEvent(this.navMenu as HTMLElement, "nav-closed");
            this.setInert();
        } else {
            this.navButton!.setAttribute("aria-expanded", "true");
            this.emitEvent(this.navMenu as HTMLElement, "nav-opened");
            this.unsetInert();

            // Have to delay because of inert
            setTimeout(() => {
                (document.querySelector(".nav-link") as HTMLAnchorElement).focus();
            }, 100);
        }
    }

    /**
     * Emit a custom event
     * @param eventElement HTMLElement
     * @param eventName string
     */
    private emitEvent(eventElement: HTMLElement, eventName: string) {
        const createdEvent = new CustomEvent(eventName);

        eventElement!.dispatchEvent(createdEvent);
    }
}
